import { AcGameObject } from "./AcGameObject";
import { Cell } from "./Cell";

export class Snake extends AcGameObject {
    constructor(info,gamemap){
        super();

        this.id = info.id;
        this.color = info.color;
        this.gamemap = gamemap;

        //存放蛇的身体,cells[0]存放蛇头
        this.cells = [new Cell(info.r,info.c)];

        // 下一步的目标位置
        this.next_cell = null;

        //蛇每秒走5个格子
        this.speed = 5;

        //-1表示没有指令,0,1,2,3表示上右下左
        this.direction = -1;

        // idle表示静止，move表示正在移动，die表示死亡
        this.status = "idle";

        // 4个方向行的偏移量
        this.dr = [-1,0,1,0];
        // 4个方向列的偏移量
        this.dc = [0,1,0,-1];

        //回合数
        this.step = 0;

        //允许的误差
        this.eps = 1e-2;

        // 左上角的蛇初始朝上
        this.eye_direction = 0;
        // 右上角的蛇初始朝下
        if(this.id === 1)this.eye_direction = 2;


    }

    start(){

    }

    set_direction(d){
        this.direction = d;
    }

    //检测当前回合，蛇的长度是否增加
    check_tail_increasing(){
        if(this.step <= 10)return true;
        if(this.step % 3 === 1)return true;
        return false;
    }

    // 将蛇的状态变为走下一步
    next_step(){
        const d = this.direction;
        this.eye_direction = d;
        this.next_cell = new Cell(this.cells[0].r+this.dr[d],this.cells[0].c+this.dc[d]);
        this.direction = -1 //清空方向
        this.status = "move";
        this.step++;

        const k = this.cells.length;
        for(let i=k;i>0;i--){
            this.cells[i] = JSON.parse(JSON.stringify(this.cells[i-1]));
        }

        // 下一步操作撞了，蛇瞬间去世
        // if(!this.gamemap.check_valid(this.next_cell)){
        //     this.status = "die";
        // }
    }

    update_move(){
        const dx = this.next_cell.x - this.cells[0].x;
        const dy = this.next_cell.y - this.cells[0].y;
        const distance = Math.sqrt(dx*dx+dy*dy);

        // 走到目标点了
        if(distance < this.eps){
            /*
            /* 添加一个新蛇头，这里不加的话蛇的移动会出问题，主要是因为计算
            /* next_cell时的r和c用的是cells[0]的r和c而不是x和y，此时r和c
            /* 还是创建cells[0]的时候的r和c，因为移动只会改变x和y，所以实际
            /* 移动的目标位置会出现错误。因此要在每次头部移动完之后将新蛇头
            /* 变为next_cell。而且不变的话会离目标位置有丢丢误差，久而久之
            /* 会不好。其实可以不用cell的r和c，只用x和y，这样或许就能解决
            /* 第一个问题了
            **/
            this.cells[0] = this.next_cell;
            // 蛇没有变长，砍掉蛇尾
            // 这里也是为了确保蛇尾的x和y与r和c一致
            if(!this.check_tail_increasing()){
                this.cells.pop();
            }
            this.next_cell = null;
            this.status = "idle"; // 走完了，停下来
        }else{
            // 每两帧之间走的距离
            const move_distance = this.speed * this.timedelta / 1000;
            this.cells[0].x += move_distance * dx / distance;
            this.cells[0].y += move_distance * dy / distance;
            const k = this.cells.length;

            if(!this.check_tail_increasing()){
                const tail = this.cells[k-1],tail_target = this.cells[k-2];
                const tail_dx = tail_target.x - tail.x;
                const tail_dy = tail_target.y - tail.y;
                tail.x += move_distance * tail_dx / distance;
                tail.y += move_distance * tail_dy / distance;
            }
        }
    }

    update(){
        if(this.status === 'move'){
            this.update_move();
        }
        if(this.status === 'die'){
            this.color = 'white';
        }
        this.render();
    }

    render() {
        const L = this.gamemap.L;
        const ctx = this.gamemap.ctx;

        ctx.fillStyle = this.color;
        //of是遍历元素，in是遍历下标
        for(const cell of this.cells){
            ctx.beginPath();
            //圆心横坐标，圆心纵坐标，半径，起始角度，终止角度
            ctx.arc(cell.x*L,cell.y*L,L/2 * 0.8,0,Math.PI*2);
            ctx.fill();
        }

        // 让蛇的两个圆形身体连起来，宽度乘0.8，长不变，这样重合时好看点
        for(let i=1;i<this.cells.length;i++){
            const a = this.cells[i-1],b = this.cells[i];
            if(Math.abs(a.x-b.x)<this.eps&&Math.abs(a.y-b.y)<this.eps)continue;
            if(Math.abs(a.x-b.x)<this.eps){
                // 四个参数分别为左上角横纵坐标，矩形的长宽
                ctx.fillRect((a.x-0.5*0.8)*L,Math.min(a.y,b.y)*L,L*0.8,Math.abs(a.y-b.y)*L);
            }else{
                ctx.fillRect(Math.min(a.x,b.x)*L,(a.y-0.5*0.8)*L,Math.abs(a.x-b.x)*L,L*0.8);
            }
        }

        // 画蛇的眼睛
        ctx.fillStyle = 'black';
        ctx.beginPath();
        if(this.eye_direction === 0 || this.eye_direction === 2){
            ctx.arc((this.cells[0].x-0.15)*L,(this.cells[0].y+this.dr[this.eye_direction]*0.15)*L,0.1*L,0,Math.PI*2);
            ctx.arc((this.cells[0].x+0.15)*L,(this.cells[0].y+this.dr[this.eye_direction]*0.15)*L,0.1*L,0,Math.PI*2);
        }else{
            ctx.arc((this.cells[0].x+this.dc[this.eye_direction]*0.15)*L,(this.cells[0].y-0.15)*L,0.1*L,0,Math.PI*2);
            ctx.arc((this.cells[0].x+this.dc[this.eye_direction]*0.15)*L,(this.cells[0].y+0.15)*L,0.1*L,0,Math.PI*2);
        }
        ctx.fill();
    }
}