import { AcGameObject } from "./AcGameObject";
import { Snake } from "./Snake";
import { Wall } from "./Wall";

export class GameMap extends AcGameObject{
    constructor(ctx,parent,store){
        super();

        this.ctx=ctx;
        this.parent=parent;
        this.store=store;
        this.L=0;

        this.rows=13;
        this.cols=14;

        this.inner_walls_count=20;

        this.walls=[];

        this.snakes = [
            new Snake({id: 0,color: "#4876EC",r: this.rows-2,c: 1},this),
            new Snake({id: 1,color: "#F94848",r: 1,c: this.cols-2},this)
        ];
    }
    
    //创建地图逻辑转移到后端实现，前端代码可删去
    
    // dfs(x,y,g,dx,dy){
    //     g[x][y]=true;
    //     for(let i=0;i<4;i++){
    //         let tx=x+dx[i],ty=y+dy[i];
    //         if(tx<1||tx>this.rows-2||ty<1||ty>this.cols-2)continue;
    //         if(g[tx][ty])continue;
    //         this.dfs(tx,ty,g,dx,dy);
    //     }
    // }

    // check_connectivity(g){
    //     let dx=[1,0,-1,0],dy=[0,1,0,-1];
    //     this.dfs(this.rows-2,1,g,dx,dy);
    //     for(let r=1;r<this.rows-1;r++){
    //         for(let c=1;c<this.cols-1;c++){
    //             if(!g[r][c])return false;
    //         }
    //     }
    //     return true;
    // }

    create_walls(){
        console.log(this.store.state.pk.gamemap);
        const g = this.store.state.pk.gamemap;

        for(let r=0;r<this.rows;r++){
            for(let c=0;c<this.cols;c++){
                if(g[r][c])this.walls.push(new Wall(r,c,this));
            }
        }
        // //数组是向下为x轴，向右为y轴
        // //ctx画图时向右为x轴，向下为y轴
        // const g=[];
        // for(let r=0;r<this.rows;r++){
        //     g[r]=[];
        //     for(let c=0;c<this.cols;c++){
        //         g[r][c]=false;
        //     }
        // }

        // //四周加墙
        // for(let r=0;r<this.rows;r++){
        //     g[r][0]=g[r][this.cols-1]=true;
        // }
        // for(let c=0;c<this.cols;c++){
        //     g[0][c]=g[this.rows-1][c]=true;
        // }

        // //随机
        // for(let i=0;i<this.inner_walls_count/2;i++){
        //     for(let j=0;j<1000;j++){
        //         let r=parseInt(Math.random()*this.rows);
        //         let c=parseInt(Math.random()*this.cols);
        //         if(g[r][c])continue;
        //         if(r==this.rows-2&&c==1)continue;
        //         if(r==1&&c==this.cols-2)continue;
        //         //从正方形 地图改为长方形
        //         g[r][c]=g[this.rows-1-r][this.cols-1-c]=true;
        //         break;
        //     }
        // }

        // const copy_g=JSON.parse(JSON.stringify(g));
        // if(!this.check_connectivity(g))return false;

        // for(let r=0;r<this.rows;r++){
        //     for(let c=0;c<this.cols;c++){
        //         if(copy_g[r][c])this.walls.push(new Wall(r,c,this));
        //     }
        // }

        // return true;
    }

    add_listening_events(){
        if(this.store.state.record.is_record){
            let k = 0;
            const a_steps = this.store.state.record.a_steps;
            const b_steps = this.store.state.record.b_steps;
            const loser = this.store.state.record.record_loser;
            const [snake0,snake1] = this.snakes;

            // 每300毫秒执行一次
            const interval_id = setInterval(() => {
                // 这里到长度-1是因为最后一步有条蛇操作死亡，不用显示出来
                if(k>=a_steps.length-1){
                    if(loser === "all" || loser === "A"){
                        snake0.status = "die";
                    }
                    if(loser === "all" || loser === "B"){
                        snake1.status = "die";
                    }
                    clearInterval(interval_id);
                }else{
                    snake0.set_direction(parseInt(a_steps[k]));
                    snake1.set_direction(parseInt(b_steps[k]));
                }
                k++;
            },300);
        }else{
            this.ctx.canvas.focus();

            //const [snake0,snake1] = this.snakes;
             
            this.ctx.canvas.addEventListener("keydown",e =>{
                // if(e.key === 'w')snake0.set_direction(0);
                // else if(e.key === 'd')snake0.set_direction(1);
                // else if(e.key === 's')snake0.set_direction(2);
                // else if(e.key === 'a')snake0.set_direction(3);
                // else if(e.key === 'ArrowUp')snake1.set_direction(0);
                // else if(e.key === 'ArrowRight')snake1.set_direction(1);
                // else if(e.key === 'ArrowDown')snake1.set_direction(2);
                // else if(e.key === 'ArrowLeft')snake1.set_direction(3);
                let d = -1;
                if(e.key === 'w')d=0;
                else if(e.key === 'd')d=1;
                else if(e.key === 's')d=2;
                else if(e.key === 'a')d=3;
    
                if(d>=0){
                    this.store.state.pk.socket.send(JSON.stringify({
                        event: "move",
                        direction: d,
                    }));
                }
            });
        }
    }

    start(){
        // for(let i=0;i<1000;i++){
        //     if(this.create_walls())break;
        // }
        this.create_walls();
        this.add_listening_events();
    }

    //判断两条蛇是否都准备好下一回合了
    check_ready(){
        for(const snake of this.snakes){
            if(snake.status !== "idle")return false;
            if(snake.direction === -1)return false;
        }
        return true;
    }

    update_size(){
        this.L=parseInt(Math.min(this.parent.clientWidth/this.cols,this.parent.clientHeight/this.rows));
        this.ctx.canvas.width=this.L*this.cols;
        this.ctx.canvas.height=this.L*this.rows;
    }

    // 让两条蛇进入下一回合
    next_step(){
        for(const snake of this.snakes){
            snake.next_step();
        }
    }

    // 检测目标位置是否合法，没有撞到两条蛇的身体和障碍物
    check_valid(cell){
        for(const wall of this.walls){
            if(wall.r === cell.r && wall.c === cell.c)return false;
        }
        for(const snake of this.snakes){
            let k = snake.cells.length;
            //当蛇尾会前进的时候，蛇尾不要判断
            if(!snake.check_tail_increasing()){
                k--;
            }
            for(let i=0;i<k;i++){
                if(cell.x === snake.cells[i].x && cell.y === snake.cells[i].y)return false;
            }
        }
        return true;
    }

    update(){
        this.update_size();
        if(this.check_ready()){
            this.next_step();
        }
        this.render();
    }

    render(){
        const color_even="#AAD751",color_odd="#A2D149"
        for(let r=0;r<this.rows;r++){
            for(let c=0;c<this.cols;c++){
                if((r+c)%2){
                    this.ctx.fillStyle=color_odd;
                }
                else this.ctx.fillStyle=color_even;
                this.ctx.fillRect(c*this.L,r*this.L,this.L,this.L);
            }
        }
    }
}