<template>
    <ContentField>
        <table class="table table-striped table-hover" style="text-align: center">
            <thead>
                <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>对战结果</th>
                    <th>对战时间</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="record in records" :key="record.record.id">
                    <td>
                        <img :src="record.a_photo" class="record-user-photo">
                        &nbsp;
                        <span class="record-user-username">{{ record.a_username }}</span>
                    </td>
                    <td>
                        <img :src="record.b_photo" class="record-user-photo">
                        &nbsp;
                        <span class="record-user-username">{{ record.b_username }}</span>
                    </td>
                    <td>{{ record.result }}</td>
                    <td>{{ record.record.createtime }}</td>
                    <td>
                        <button type="button" class="btn btn-secondary" @click="open_record_content(record.record.id)">查看录像</button>    
                    </td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="Page navigation example">
            <ul class="pagination" style="float: right">
                <li class="page-item" @click="click_page(-1)">
                    <a class="page-link" href="#" aria-label="Previous">
                        <i class="bi bi-chevron-double-left"></i>
                    </a>
                </li>

                <li class="page-item" @click="click_page(-2)">
                    <a class="page-link" href="#" aria-label="Previous">
                        <i class="bi bi-caret-left"></i>
                    </a>
                </li>

                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number" @click="click_page(page.number)">
                    <a class="page-link" href="#">{{ page.number }}</a>
                </li>
                
                <li class="page-item" @click="click_page(-3)">
                    <a class="page-link" href="#" aria-label="Next">
                        <i class="bi bi-caret-right"></i>
                    </a>
                </li>

                <li class="page-item" @click="click_page(-4)">
                    <a class="page-link" href="#" aria-label="Previous">
                        <i class="bi bi-chevron-double-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import $ from 'jquery'
import router from '../../router/index'

export default{
    components:{
        ContentField,
    },
    setup(){
        const store = useStore();
        let records = ref([]);
        let current_page = 1;
        let total_records = 0;
        let pages = ref([]);

        const click_page = page => {
            let max_pages = parseInt(Math.ceil(total_records / 10));
            if(page===-1)page = current_page===1?0:1;
            else if(page===-2)page = current_page-1;
            else if(page===-3)page = current_page+1;
            else if(page===-4)page = current_page===max_pages?0:max_pages;
            if(page>=1&&page<=max_pages){
                pull_page(page);
            }
        }

        const update_pages = () => {
            let max_pages = parseInt(Math.ceil(total_records / 10));
            let new_pages = [];
            for(let i=current_page-2;i<=current_page+2;i++){
                if(i>=1&&i<=max_pages){
                    new_pages.push({
                        number: i,
                        is_active: i===current_page?'active':'',
                    });
                }
            }
            console.log(current_page);
            pages.value = new_pages;
        }

        console.log(total_records);

        const pull_page = page => {
            current_page = page;
            $.ajax({
                url: "https://snake.molimark.com.cn/api/record/getList/",
                data: {
                    page,
                },
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp){
                    records.value = resp.records;
                    total_records = resp.records_cnt;
                    console.log(resp.records_cnt);
                    update_pages();
                },
                error(resp){
                    console.log(resp);
                }
            })
        }

        pull_page(current_page);

        // 将字符串转为二维数组
        const stringTo2D= map => {
            let g = [];
            for(let i=0,k=0;i<13;i++){
                let line = [];
                for(let j=0;j<14;j++,k++){
                    if(map[k]==='0')line.push(0);
                    else line.push(1);
                }
                g.push(line);
            }
            return g;
        }

        const open_record_content = recordId => {
            console.log("records_value: " + records.value);
            for(const record of records.value){
                if(record.record.id === recordId){
                    store.commit("updateIsRecord",true);
                    store.commit("updateGame",{
                        map: stringTo2D(record.record.map),
                        a_id: record.record.aId,
                        a_sx: record.record.aSx,
                        a_sy: record.record.aSy,
                        b_id: record.record.bId,
                        b_sx: record.record.bSx,
                        b_sy: record.record.bSy,
                    });
                    console.log(store.state.pk.gamemap);
                    console.log(record.record.aSteps);
                    store.commit("updateSteps",{
                        a_steps: record.record.aSteps,
                        b_steps: record.record.bSteps,
                    });
                    console.log("a_steps: " + store.state.record.a_steps);
                    store.commit("updateRecordLoser",record.record.loser);
                    router.push({
                        name: 'record_content',
                        params: {
                            // 在js里，如果key和value一样的话，写一个就可以了
                            //recordId: recordId,
                            recordId,
                        }    
                    });
                    break;
                }
            }
        }

        return {
            records,
            open_record_content,
            pages,
            click_page,
        }

    }
}
</script>

<style scoped>
img.record-user-photo {
    width: 4vh;
    border-radius: 50%;
}
</style>