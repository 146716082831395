<template>
<ContentField>
    404
</ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'

export default{
    components:{
        ContentField,
    }
}
</script>

<style scoped>

</style>
