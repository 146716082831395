<template>
    <ContentField v-if="!$store.state.user.pulling_info">
        <!-- bootstrap的grid分成12格 -->
        <div class="row justify-content-md-center">
            <div class="col-3">
                <!-- .prevent表示阻止默认事件的修饰符，提交以后不会刷新页面。
                     阻止默认事件就是指有些标签本身会存在事件，如a标签的跳转，
                     form表单中的submit按钮的提交事件等，某些时候想执行自己设置的事件，
                     这个时候就需要阻止标签的默认事件的执行。在vue中，只需要使用
                     .prevent修饰符就可以。
                -->
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">提交</button>
                </form>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../../../components/ContentField.vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import router from '../../../router/index'

export default{
    components:{
        ContentField,
    },
    setup(){
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');
        // store.commit("updatePullingInfo",true);

        // const jwt_token = localStorage.getItem("jwt_token");
        // if(jwt_token){
        //     store.commit("updateToken",jwt_token);
        //     store.dispatch("getinfo",{
        //         success(){
        //             router.push({ name : "home" });
        //             store.commit("updatePullingInfo",false);
        //         },
        //         error(){
        //             store.commit("updatePullingInfo",false);
        //         }
        //     })
        // }else{
        //     store.commit("updatePullingInfo",false);
        // }

        const login = () => {
            error_message.value = '';
            store.dispatch("login",{
                username: username.value,
                password: password.value,
                success(){
                    store.dispatch("getinfo",{
                        success(){
                            router.push({ name: 'home' });
                            console.log(store.state.user);
                        },
                    })
                },
                error(){
                    error_message.value = "用户名或密码错误";
                }
            });
        }

        return {
            username,
            password,
            error_message,
            login,
        }
    }
}
</script>

<style scoped>
button {
    /* 占到父组件宽度的100% */
    width: 100%;
}
div.error-message {
    color: red;
}
</style>
