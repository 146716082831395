import { createRouter, createWebHistory } from 'vue-router'
import PkIndexView from '../views/pk/PkIndexView'
import RecordIndexView from '../views/record/RecordIndexView'
import RanklistIndexView from '../views/ranklist/RanklistIndexView'
import UserBotIndexView from '../views/user/bot/UserBotIndexView'
import NotFound from '../views/error/NotFound'
import UserAccountLoginView from '../views/user/account/UserAccountLoginView'
import UserAccountRegisterView from '../views/user/account/UserAccountRegisterView'
import RecordContentView from '../views/record/RecordContentView'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:"/pk/",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/pk/',
    name: 'pk_index',
    component: PkIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    /**path不区分大小写，name区分大小写 */
    path: '/record/',
    name: 'record_index',
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    /**path不区分大小写，name区分大小写 */
    path: '/record/:recordId/',
    name: 'record_content',
    component: RecordContentView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/ranklist/',
    name: 'ranklist_index',
    component: RanklistIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/user/bot/',
    name: 'user_bot_index',
    component: UserBotIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/user/account/login/',
    name: 'user_account_login',
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/user/account/register/',
    name: 'user_account_register',
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/404/',
    name: '404',
    component: NotFound,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect:"/404/"
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to,from,next) => {
  store.commit("updatePullingInfo",true);

  const jwt_token = localStorage.getItem("jwt_token");
  if(jwt_token){
    store.commit("updateToken",jwt_token);
    store.dispatch("getinfo",{
        success(){
            next();
            store.commit("updatePullingInfo",false);
        },
        error(){
            alert("token无效，请重新登录！");
            /**
            ** 这里必须要dispatch("logout")，不然当手动设置一个
            ** 错误的token后每次都会触发error()，然后重定向到
            ** user_acccount_login，然后又触发error()，导致死循环
             */
            store.dispatch("logout");
            next({name:"user_account_login"});
            store.commit("updatePullingInfo",false);
        }
    })
  }else{
    //登录界面不需要授权，因此错误token第一次进入error()后，第二次进入else处第一个to.meta.requestAuth=false，直接触发next()
    if(to.meta.requestAuth && !store.state.user.is_login){
      next({name:"user_account_login"});
    }else{
      next();
    }
    //这一句代码还会执行，next()会在beforeEach函数结束后重定向或放行
    console.log("hi");
    store.commit("updatePullingInfo",false);
  }
})

export default router
