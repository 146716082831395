<template>
    <ContentField>
        <table class="table table-striped table-hover" style="text-align: center">
            <thead>
                <tr>
                    <th>排名</th>
                    <th>玩家</th>
                    <th>天梯分</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td>
                        {{ user.index }}
                    </td>
                    <td>
                        <img :src="user.user.photo" class="ranklist-user-photo">
                        &nbsp;
                        <span class="ranklist-user-username">{{ user.user.username }}</span>
                    </td>
                    <td>{{ user.user.rating }}</td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="Page navigation example">
            <ul class="pagination" style="float: right">
                <li class="page-item" @click="click_page(-1)">
                    <a class="page-link" href="#" aria-label="Previous">
                        <i class="bi bi-chevron-double-left"></i>
                    </a>
                </li>

                <li class="page-item" @click="click_page(-2)">
                    <a class="page-link" href="#" aria-label="Previous">
                        <i class="bi bi-caret-left"></i>
                    </a>
                </li>

                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number" @click="click_page(page.number)">
                    <a class="page-link" href="#">{{ page.number }}</a>
                </li>
                
                <li class="page-item" @click="click_page(-3)">
                    <a class="page-link" href="#" aria-label="Next">
                        <i class="bi bi-caret-right"></i>
                    </a>
                </li>

                <li class="page-item" @click="click_page(-4)">
                    <a class="page-link" href="#" aria-label="Previous">
                        <i class="bi bi-chevron-double-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import $ from 'jquery'

export default{
    components:{
        ContentField,
    },
    setup(){
        const store = useStore();
        let users = ref([]);
        let current_page = 1;
        let total_users = 0;
        let pages = ref([]);

        console.log(total_users);

        const click_page = page => {
            console.log(page);
            let max_pages = parseInt(Math.ceil(total_users / 10));
            if(page===-1)page = current_page===1?0:1;
            else if(page===-2)page = current_page-1;
            else if(page===-3)page = current_page+1;
            else if(page===-4)page = current_page===max_pages?0:max_pages;
            if(page>=1&&page<=max_pages){
                pull_user(page);
            }
        }

        const update_pages = () => {
            let max_pages = parseInt(Math.ceil(total_users / 10));
            let new_pages = [];
            for(let i=current_page-2;i<=current_page+2;i++){
                if(i>=1&&i<=max_pages){
                    new_pages.push({
                        number: i,
                        is_active: i===current_page?'active':'',
                    });
                }
            }
            console.log(current_page);
            pages.value = new_pages;
        }

        console.log(total_users);

        const pull_user = page => {
            current_page = page;
            $.ajax({
                url: "https://snake.molimark.com.cn/api/ranklist/getList/",
                data: {
                    page,
                },
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp){
                    users.value = resp.users;
                    total_users = resp.users_cnt;
                    update_pages();
                    console.log(total_users);
                },
                error(resp){
                    console.log(resp);
                }
            })
        }

        pull_user(current_page);

        return {
            users,
            pages,
            click_page,
            current_page,
        }
    }
}
</script>

<style scoped>
img.ranklist-user-photo {
    width: 4vh;
    border-radius: 50%;
}
</style>
