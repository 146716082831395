<template>
    <PlayGround v-if="$store.state.pk.status === 'playing'">
        对战
    </PlayGround>
    <!-- 在script里写不用加$，在template里写要加$ -->
    <MatchGround v-if="$store.state.pk.status === 'matching'">
        匹配
    </MatchGround>
    <ResultBoard v-if="$store.state.pk.loser !== 'none'">

    </ResultBoard>
    <div class="user-color" v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.a_id)">左下角</div>
    <div class="user-color" v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.b_id)">右上角</div>
</template>

<script>
import PlayGround from '../../components/PlayGround.vue'
import MatchGround from '../../components/MatchGround.vue'
import ResultBoard from '../../components/ResultBoard.vue'
import { onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'

export default{
    components:{
        PlayGround,
        MatchGround,
        ResultBoard,
    },
    setup(){
        const store = useStore();
        //`这个符号不是很懂:这个是ES6模板字符串
        const socketUrl = `wss://snake.molimark.com.cn/websocket/${store.state.user.token}/`

        store.commit("updateIsRecord",false);

        let socket = null;

        onMounted(() => {
            socket = new WebSocket(socketUrl);

            socket.onopen = () => {
                console.log("connect!");
                store.commit("updateSocket",socket);
            }

            socket.onmessage = msg => {
                //msg格式是由Spring定义的，不同框架不一样
                const data = JSON.parse(msg.data);
                if(data.event === "start-matching"){ //匹配成功
                    store.commit("updateOpponent",{
                        username: data.opponent_username,
                        photo: data.opponent_photo,
                    });
                    store.commit("updateGame",{
                        map: data.game.map,
                        a_id: data.game.a_id,
                        a_sx: data.game.a_sx,
                        a_sy: data.game.a_sy,
                        b_id: data.game.b_id,
                        b_sx: data.game.b_sx,
                        b_sy: data.game.b_sy,
                    });
                    setTimeout(() => {
                        store.commit("updateStatus","playing");
                    },200);
                }else if(data.event === "move"){
                    console.log(data);
                    const game = store.state.pk.gameObject;
                    const [snake0,snake1] = game.snakes;
                    snake0.set_direction(data.a_direction);
                    snake1.set_direction(data.b_direction);
                }else if(data.event === "result"){
                    console.log(data);
                    const game = store.state.pk.gameObject;
                    const [snake0,snake1] = game.snakes;
                    if(data.loser === "all" || data.loser === "A"){
                        snake0.status = "die";
                    }
                    if(data.loser === "all" || data.loser === "B"){
                        snake1.status = "die";
                    }
                    store.commit("updateLoser",data.loser);

                }
                console.log(data);
            }

            socket.onclose = () => {
                console.log("disconnected!")
            }
        });

        onUnmounted(() => {
            socket.close();
            store.commit("updateStatus","matching");
            // 清空对局结果框
            store.commit("updateLoser","none");
        })
    }
}
</script>

<style scoped>
div.user-color {
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 600;
}
</style>
