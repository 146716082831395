<template>
    <ContentField>
        <!-- bootstrap的grid分成12格 -->
        <div class="row justify-content-md-center">
            <div class="col-3">
                <!-- .prevent表示阻止默认事件的修饰符，提交以后不会刷新页面。
                     阻止默认事件就是指有些标签本身会存在事件，如a标签的跳转，
                     form表单中的submit按钮的提交事件等，某些时候想执行自己设置的事件，
                     这个时候就需要阻止标签的默认事件的执行。在vue中，只需要使用
                     .prevent修饰符就可以。
                -->
                <form @submit.prevent="register">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="mb-3">
                        <label for="confirmPassword" class="form-label">确认密码</label>
                        <input v-model="confirmPassword" type="password" class="form-control" id="confirmPassword" placeholder="请输入确认密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">提交</button>
                </form>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../../../components/ContentField.vue'
import {ref} from 'vue'
import router from '../../../router/index'
import $ from 'jquery'

export default{
    components:{
        ContentField,
    },
    setup(){
        let username = ref('')
        let password = ref('')
        let confirmPassword = ref('')
        let error_message = ref('')

        const register = () => {
            $.ajax({
                url: "https://snake.molimark.com.cn/api/user/account/register/",
                type: "post",
                data: {
                    username:username.value,
                    password:password.value,
                    confirmPassword:confirmPassword.value,
                },
                success(resp){
                    if(resp.error_message === "success"){
                        router.push({name : "user_account_login"})
                    }else{
                        error_message.value = resp.error_message;
                    }
                },
                error(resp){
                    console.log(resp);
                }

            })
            
        }

        return {
            username,
            password,
            confirmPassword,
            error_message,
            register
        }
    }
}
</script>

<style scoped>
button {
    /* 占到父组件宽度的100% */
    width: 100%;
}
div.error-message {
    color: red;
}
</style>
